import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import ResultView from './ResultView';
import ControlPanel from './ControlPanel';
import ActionPanel from './ActionPanel';
import Card from '../Card';
import AlignedButtonLayout from './AlignedButtonLayout';
import * as XLSX from 'xlsx';
import { X, Info, ChevronUp, ChevronDown, MessageSquarePlus } from 'lucide-react';
import QueryInput from './QueryInput';
import useFollowUpQuery from '../../hooks/useFollowUpQuery';

const IntermediatePopup = ({ position, onClick }) => (
  <div 
    className="fixed z-50 cursor-pointer intermediate-popup"
    style={{ 
      left: `${position.x}px`, 
      top: `${position.y}px`,
      transform: 'translate(-50%, -50%)'
    }}
    onClick={onClick}
  >
    <div className="w-10 h-10 rounded-full bg-white shadow-custom animate-popIn flex items-center justify-center">
      <img src="/logo512.png" alt="App Icon" className="w-8 h-8" />
    </div>
  </div>
);

const QueryResultCard = ({ 
  query, 
  currentUser, 
  onSaveQuery, 
  onExplanationClick,
  isExplanationLoading,
  isExplanationPending,
  isDashboardMode = false,
  onSaveCardSettings,
  cardWidth,
  onCardWidthChange,
  onFollowUpQuery,
  onClose,
  isAdmin,
  isLoading
}) => {
  const [viewMode, setViewMode] = useState((query.cardSettings && query.cardSettings.viewMode) || 'table');
  const [chartType, setChartType] = useState((query.cardSettings && query.cardSettings.chartType) || 'bar');
  const [selectedXAxis, setSelectedXAxis] = useState((query.cardSettings && query.cardSettings.selectedXAxis) || '');
  const [selectedYAxis, setSelectedYAxis] = useState((query.cardSettings && query.cardSettings.selectedYAxis) || '');
  const [sortColumn, setSortColumn] = useState((query.cardSettings && query.cardSettings.sortColumn) || '');
  const [sortOrder, setSortOrder] = useState((query.cardSettings && query.cardSettings.sortOrder) || 'asc');
  const [processedData, setProcessedData] = useState([]);
  const [isVisible, setIsVisible] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [localCardWidth, setLocalCardWidth] = useState(cardWidth || '100%');
  const [isExplanationCollapsed, setIsExplanationCollapsed] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [statsData, setStatsData] = useState(null);
  const [isNumberFormatted, setIsNumberFormatted] = useState(true);

  const cardRef = useRef(null);
  const popupRef = useRef(null);
  const modalRef = useRef(null);

  const {
    selectedText,
    showIntermediatePopup,
    intermediatePopupPosition,
    showFollowUpInput,
    isFromHighlight,
    handleTextSelection,
    handleIntermediatePopupClick,
    handleFollowUpSubmit,
    handleClickOutside,
    handleEscKey,
    setShowFollowUpInput,
    resetFollowUpState
  } = useFollowUpQuery(async (submittedQuery, selectedText, originalSqlQuery) => {
    setShowFollowUpInput(false);
    await onFollowUpQuery(submittedQuery, selectedText, originalSqlQuery);
  });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    const handleOutsideClick = (event) => handleClickOutside(event, modalRef);

    window.addEventListener('resize', handleResize);
    document.addEventListener('mousedown', handleOutsideClick);
    document.addEventListener('keydown', handleEscKey);

    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [handleClickOutside, handleEscKey]);

  useEffect(() => {
    setLocalCardWidth(cardWidth || '100%');
  }, [cardWidth]);

  useEffect(() => {
    if (query && query.result && query.result.length > 0) {
      const firstRow = query.result[0];
      const keys = Object.keys(firstRow);
      
      const dateColumn = keys.find(key => {
        const value = firstRow[key];
        if (typeof value === 'object' && value !== null && 'value' in value) {
          return value.value && typeof value.value === 'string' && 
                 (value.value.match(/^\d{4}-\d{2}-\d{2}/) || value.value.match(/^\d{4}-\d{2}/));
        }
        return value && typeof value === 'string' && 
               (value.match(/^\d{4}-\d{2}-\d{2}/) || value.match(/^\d{4}-\d{2}/));
      }) || keys[0];
  
      const valueColumn = keys.find(key => {
        const value = firstRow[key];
        return typeof value === 'number' || (typeof value === 'object' && value !== null && typeof value.value === 'number');
      }) || keys[1] || '';
  
      let xAxis = query.cardSettings?.selectedXAxis || dateColumn;
      let yAxis = query.cardSettings?.selectedYAxis || valueColumn;
  
      // New logic to handle cases where X and Y axes are the same
      if (xAxis === yAxis) {
        // Find all numeric columns
        const numericColumns = keys.filter(key => {
          const value = firstRow[key];
          return typeof value === 'number' || (typeof value === 'object' && value !== null && typeof value.value === 'number');
        });
  
        if (numericColumns.length > 1) {
          // If there are multiple numeric columns, use the first for X and the second for Y
          xAxis = numericColumns[0];
          yAxis = numericColumns[1];
        } else {
          // If there's only one numeric column, use it for X and the second column (whatever it is) for Y
          xAxis = numericColumns[0] || keys[0];
          yAxis = keys[1] || '';
        }
      }
  
      setSelectedXAxis(xAxis);
      setSelectedYAxis(yAxis);
  
      const isXAxisDate = (() => {
        const value = firstRow[xAxis];
        if (typeof value === 'object' && value !== null && 'value' in value) {
          return value.value && typeof value.value === 'string' && 
                 (value.value.match(/^\d{4}-\d{2}-\d{2}/) || value.value.match(/^\d{4}-\d{2}/));
        }
        return value && typeof value === 'string' && 
               (value.match(/^\d{4}-\d{2}-\d{2}/) || value.match(/^\d{4}-\d{2}/));
      })();
  
      setSortColumn(query.cardSettings?.sortColumn || (isXAxisDate ? xAxis : yAxis));
      setSortOrder(query.cardSettings?.sortOrder || 'asc');
  
      if (!query.cardSettings?.chartType) {
        setChartType(isXAxisDate ? 'line' : 'bar');
      }
    }
  }, [query]);

  const handleFollowUpButtonClick = () => {
    resetFollowUpState();
    setShowFollowUpInput(true);
  };
  
  const handleNumberFormatToggle = useCallback((checked) => {
    setIsNumberFormatted(checked);
    if (isDashboardMode && onSaveCardSettings) {
      onSaveCardSettings({ ...query.cardSettings, isNumberFormatted: checked });
    }
  }, [isDashboardMode, onSaveCardSettings, query.cardSettings]);
  
  const resultDimensions = useMemo(() => {
    return query && query.result && query.result.length > 0 ? Object.keys(query.result[0]).length : 0;
  }, [query]);

  const isSingleValue = useMemo(() => {
    return query && query.result && query.result.length === 1 && resultDimensions === 1;
  }, [query, resultDimensions]);

  const showControls = useMemo(() => {
    return query && query.result && query.result.length > 1 && resultDimensions >= 2;
  }, [query, resultDimensions]);

  const canShowGraph = useMemo(() => {
    if (!query || !query.result || query.result.length === 0) return false;
    if (query.result.length === 1 && resultDimensions <= 3) return false;
    return query.result.length > 1 && resultDimensions >= 2;
  }, [query, resultDimensions]);

  const handleCardWidthChange = useCallback((newWidth) => {
    if (!isMobile && onCardWidthChange) {
      setLocalCardWidth(newWidth);
      onCardWidthChange(newWidth);
      if (isDashboardMode && onSaveCardSettings) {
        onSaveCardSettings({ cardWidth: newWidth });
      }
    }
  }, [onCardWidthChange, isMobile, isDashboardMode, onSaveCardSettings]);

  const handleViewModeChange = useCallback((newViewMode) => {
    setViewMode(newViewMode);
    if (newViewMode !== 'table') {
      setChartType(newViewMode);
    }
    if (isDashboardMode && onSaveCardSettings) {
      onSaveCardSettings({ ...query.cardSettings, viewMode: newViewMode, chartType: newViewMode });
    }
  }, [isDashboardMode, onSaveCardSettings, query.cardSettings]);

  const handleExcelDownload = useCallback(() => {
    if (query.result && query.result.length > 0) {
      const worksheet = XLSX.utils.json_to_sheet(query.result);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
      XLSX.writeFile(workbook, "query_result.xlsx");
    }
  }, [query.result]);

  const handleCloseCard = () => {
    setIsVisible(false);
    if (onClose) {
      onClose();
    }
  };

  const handleSortChange = useCallback((column, order) => {
    setSortColumn(column);
    setSortOrder(order);
    if (isDashboardMode && onSaveCardSettings) {
      onSaveCardSettings({ ...query.cardSettings, sortColumn: column, sortOrder: order });
    }
  }, [isDashboardMode, onSaveCardSettings, query.cardSettings]);

  const handleAxisChange = useCallback((axis, value) => {
    if (axis === 'x') {
      setSelectedXAxis(value);
      if (isDashboardMode && onSaveCardSettings) {
        onSaveCardSettings({ ...query.cardSettings, selectedXAxis: value });
      }
    } else if (axis === 'y') {
      setSelectedYAxis(value);
      if (isDashboardMode && onSaveCardSettings) {
        onSaveCardSettings({ ...query.cardSettings, selectedYAxis: value });
      }
    }
  }, [isDashboardMode, onSaveCardSettings, query.cardSettings]);

  const getCardTitle = useCallback(() => {
    let titleText;
    
    if (isDashboardMode && query.name) {
      titleText = query.name;
    } else {
      titleText = query.userQuery || query.name || "Query Result";
    }
  
    if (typeof titleText !== 'string') {
      console.warn('Unexpected titleText type:', titleText);
      titleText = "Query Result";
    }

    const isFollowUp = query.isFollowUp || 
                       titleText.toLowerCase().includes('follow-up') || 
                       titleText.toLowerCase().includes('follow up') ||
                       (query.originalQuery && query.originalQuery !== query.userQuery);
  
    if (isFollowUp) {
      titleText = titleText.replace(/^(follow-up:|follow up:)\s*/i, '');
      titleText = `Follow-up: ${titleText}`;
    }
  
    if (!isDashboardMode) {
      const words = titleText.split(' ');
      if (words.length > 10) {
        titleText = `${words.slice(0, 10).join(' ')}...`;
      }
    }
  
    return `${titleText}`;
  }, [query.name, query.userQuery, query.isFollowUp, query.originalQuery, isDashboardMode]);

  const cardStyle = useMemo(() => {
    const baseStyle = "bg-white shadow-card rounded-lg p-4 mb-4 flex flex-col";
    if (isDashboardMode) {
      return `${baseStyle}`;
    } else {
      return `${baseStyle} border-l-4 border-secondary bg-gradient-to-r from-secondary/5 to-secondary/20`;
    }
  }, [isDashboardMode]);

  const toggleExplanationCollapse = () => {
    setIsExplanationCollapsed(prev => !prev);
  };

  const toggleEditMode = () => {
    setIsEditMode(prev => !prev);
  };

  const calculateStats = useCallback(() => {
    if (query && query.result && query.result.length > 0 && selectedYAxis) {
      const values = query.result.map(row => parseFloat(row[selectedYAxis])).filter(val => !isNaN(val));
      
      if (values.length > 0) {
        const sum = values.reduce((acc, val) => acc + val, 0);
        const avg = sum / values.length;

        setStatsData({
          sum: sum,
          avg: avg
        });
      } else {
        setStatsData(null);
      }
    } else {
      setStatsData(null);
    }
  }, [query, selectedYAxis]);

  useEffect(() => {
    calculateStats();
  }, [calculateStats]);

  if (!isVisible) {
    return null;
  }

  return (
    <div className="relative">
      <Card 
        ref={cardRef}
        className={`overflow-hidden relative flex flex-col ${cardStyle}`}
        style={{ 
          width: isMobile ? '100%' : (isDashboardMode ? localCardWidth : '100%'),
          minHeight: '400px',
        }}
      >
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold text-gray-700">{getCardTitle()}</h3>
          <button
            onClick={handleCloseCard}
            className="p-1 rounded-full transition-colors duration-200 hover:bg-gray-200"
            aria-label="Close result"
          ><X size={18} className="text-gray-700" />
          </button>
        </div>
        {query.truncationMessage && (
          <div className="mb-4 p-3 rounded bg-blue-100 text-blue-800 flex items-start">
            <Info className="mr-2 flex-shrink-0 mt-1" size={18} />
            <p>{query.truncationMessage}</p>
          </div>
        )}
        <AlignedButtonLayout
          viewMode={viewMode}
          setViewMode={handleViewModeChange}
          chartType={chartType}
          setChartType={setChartType}
          canShowGraph={canShowGraph}
          isSingleValue={isSingleValue}
          hasResults={query.result && query.result.length > 0}
          isMobile={isMobile}
          isDashboardMode={isDashboardMode}
          isEditMode={isEditMode}
          toggleEditMode={toggleEditMode}
          statsData={statsData}
          yAxisLabel={selectedYAxis}
          isNumberFormatted={isNumberFormatted}
          onNumberFormatToggle={handleNumberFormatToggle}
        />
        {(!isDashboardMode || (isDashboardMode && isEditMode)) && canShowGraph && viewMode !== 'table' && (
          <ControlPanel
            selectedXAxis={selectedXAxis}
            setSelectedXAxis={(value) => handleAxisChange('x', value)}
            selectedYAxis={selectedYAxis}
            setSelectedYAxis={(value) => handleAxisChange('y', value)}
            sortColumn={sortColumn}
            setSortColumn={(column) => handleSortChange(column, sortOrder)}
            sortOrder={sortOrder}
            setSortOrder={(order) => handleSortChange(sortColumn, order)}
            columns={query.result && query.result.length > 0 ? Object.keys(query.result[0]) : []}
            schema={query.schema}
            isVisible={!isDashboardMode || isEditMode}
          />
        )}
        <div className="flex-grow overflow-y-auto mt-4 pb-2" onMouseUp={handleTextSelection}>
          <ResultView 
            result={query}
            viewMode={viewMode}
            chartType={chartType}
            selectedXAxis={selectedXAxis}
            selectedYAxis={selectedYAxis}
            sortColumn={sortColumn}
            sortOrder={sortOrder}
            isSingleValue={isSingleValue}
            onProcessedDataChange={setProcessedData}
            isNumberFormatted={isNumberFormatted}
          />
        </div>
        <div className="mt-auto flex justify-between items-center">
          <button
            onClick={handleFollowUpButtonClick}
            className="p-1.5 rounded-full transition-all duration-200 bg-white hover:bg-gray-100 border border-gray-200 shadow-sm hover:shadow-md"
            title="Ask follow-up question"
            style={{
              transform: 'scale(1)',
              transition: 'transform 0.2s ease-in-out'
            }}
            onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.1)'}
            onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
          >
            <img src="/favicon.ico" alt="App Icon" className="w-6 h-6" />
          </button>
          <ActionPanel 
            result={query} 
            onSaveQuery={onSaveQuery}
            isSavedQuery={query.isSavedQuery}
            onExplanationClick={onExplanationClick}
            isExplanationLoading={isExplanationLoading}
            isExplanationPending={isExplanationPending}
            explanation={query.explanation}
            isDashboardMode={isDashboardMode}
            isAdmin={isAdmin}
            isEmptyResult={!query.result || query.result.length === 0}
            isInDashboard={isDashboardMode}
            downloadExcel={handleExcelDownload}
          />     
        </div>
        {!isDashboardMode && query.explanation && (
          <div className="mt-4 bg-gray-50 rounded-md p-4 transition-all duration-300 ease-in-out">
            <div 
              className="flex justify-between items-center cursor-pointer" 
              onClick={toggleExplanationCollapse}
            >
              <h4 className="text-md font-semibold text-gray-700">Behind the Query</h4>
              {isExplanationCollapsed ? <ChevronDown size={18} /> : <ChevronUp size={18} />}
            </div>
            <div className={`mt-2 overflow-hidden transition-all duration-300 ease-in-out ${isExplanationCollapsed ? 'max-h-0' : 'max-h-96'}`}>
              <pre className="whitespace-pre-wrap break-words text-sm">
                {query.explanation}
              </pre>
            </div>
          </div>
        )}
      </Card>
      {showIntermediatePopup && (
        <IntermediatePopup 
          position={intermediatePopupPosition} 
          onClick={handleIntermediatePopupClick} 
        />
      )}
      {showFollowUpInput && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div ref={modalRef} className="bg-white p-6 rounded-lg shadow-xl w-full max-w-2xl">
            <h3 className="text-lg font-semibold mb-4">Follow-up Query</h3>
            {isLoading ? (
              <div className="flex justify-center items-center h-24">
                <p>Loading...</p>
              </div>
            ) : (
              <QueryInput
                onSubmit={(submittedQuery, selectedText) => handleFollowUpSubmit(submittedQuery, selectedText, query.sqlQuery)}
                isFollowUpMode={true}
                selectedText={isFromHighlight ? selectedText : (getCardTitle().slice(0, 20))}
                isCardTitle={!isFromHighlight}
                isLoading={isLoading}
              />
            )}
            <button 
              className="mt-4 text-sm text-gray-600 hover:text-gray-800"
              onClick={() => {
                setShowFollowUpInput(false);
                resetFollowUpState();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default QueryResultCard;