import React, { useState, useRef, useEffect } from 'react';
import { SendHorizontal, Pause, Play, X } from 'lucide-react';
import RecentSearches from './RecentSearches';

const placeholders = [
  "Best selling SKUs",
  "Total sales to USA",
  "Last 5 transactions"
];

const TYPING_INTERVAL = 45;
const ERASING_INTERVAL = 20;
const PAUSE_DURATION = 9000;

const QueryInput = ({ onSubmit, isLoading, isFollowUp = false, isFollowUpMode = false, selectedText = '', isCardTitle = false, fetchRecentSearches }) => {
  const [inputQuery, setInputQuery] = useState('');
  const [placeholder, setPlaceholder] = useState('');
  const [isPaused, setIsPaused] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [showRecentSearches, setShowRecentSearches] = useState(false);
  const [recentSearches, setRecentSearches] = useState([]);
  const [filteredRecentSearches, setFilteredRecentSearches] = useState([]);
  const [maxDisplayedSearches, setMaxDisplayedSearches] = useState(4);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const textareaRef = useRef(null);
  const chipRef = useRef(null);
  const timeoutRef = useRef(null);
  const recentSearchesRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputQuery.trim()) {
      console.log('Submitting query, isFollowUpMode:', isFollowUpMode);
      await onSubmit(inputQuery, isFollowUpMode, selectedText);
      setShowRecentSearches(false);
      refreshRecentSearches();
    }
  };

  const refreshRecentSearches = async () => {
    const searches = await fetchRecentSearches();
    setRecentSearches(searches);
    filterRecentSearches(inputQuery, searches);
    localStorage.setItem('recentSearches', JSON.stringify(searches));
  };

  const loadRecentSearchesFromLocalStorage = () => {
    const storedSearches = localStorage.getItem('recentSearches');
    if (storedSearches) {
      const parsedSearches = JSON.parse(storedSearches);
      setRecentSearches(parsedSearches);
      filterRecentSearches(inputQuery, parsedSearches);
    }
  };

  const filterRecentSearches = (query, searches = recentSearches) => {
    const filtered = searches.filter(search => 
      search.userQuery.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredRecentSearches(filtered.slice(0, maxDisplayedSearches));
  };

  const handleTextareaChange = (e) => {
    const newQuery = e.target.value;
    setInputQuery(newQuery);
    filterRecentSearches(newQuery);
    adjustTextareaHeight();
    setHighlightedIndex(-1);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (highlightedIndex !== -1 && filteredRecentSearches.length > 0) {
        handleSelectRecentQuery(filteredRecentSearches[highlightedIndex]);
      } else {
        handleSubmit(e);
      }
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      setHighlightedIndex((prevIndex) =>
        prevIndex < filteredRecentSearches.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setHighlightedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : -1));
    } else if (e.key === 'Escape') {
      setShowRecentSearches(false);
      setHighlightedIndex(-1);
    }
  };

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      const newHeight = Math.min(textarea.scrollHeight, 3 * 24);
      textarea.style.height = `${newHeight}px`;
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [inputQuery]);

  useEffect(() => {
    if (isFollowUpMode) {
      if (textareaRef.current && chipRef.current) {
        const chipWidth = chipRef.current.offsetWidth;
        const paddingLeft = 10;
        const cursorPosition = paddingLeft + chipWidth + 5;
        textareaRef.current.focus();
        textareaRef.current.setSelectionRange(cursorPosition, cursorPosition);
      }
      return;
    }

    let currentPlaceholderIndex = 0;
    let currentCharIndex = 0;
    let isErasing = false;

    const animatePlaceholder = () => {
      if (isPaused) {
        timeoutRef.current = setTimeout(animatePlaceholder, 100);
        return;
      }

      const currentPlaceholder = placeholders[currentPlaceholderIndex];

      if (!isErasing && currentCharIndex <= currentPlaceholder.length) {
        setPlaceholder(currentPlaceholder.slice(0, currentCharIndex));
        currentCharIndex++;
        timeoutRef.current = setTimeout(animatePlaceholder, TYPING_INTERVAL);
      } else if (!isErasing && currentCharIndex > currentPlaceholder.length) {
        isErasing = true;
        timeoutRef.current = setTimeout(animatePlaceholder, PAUSE_DURATION);
      } else if (isErasing && currentCharIndex > 0) {
        currentCharIndex--;
        setPlaceholder(currentPlaceholder.slice(0, currentCharIndex));
        timeoutRef.current = setTimeout(animatePlaceholder, ERASING_INTERVAL);
      } else if (isErasing && currentCharIndex === 0) {
        isErasing = false;
        currentPlaceholderIndex = (currentPlaceholderIndex + 1) % placeholders.length;
        timeoutRef.current = setTimeout(animatePlaceholder, TYPING_INTERVAL);
      }
    };

    animatePlaceholder();

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isPaused, isFollowUpMode]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }

    const handleScroll = () => {
      if (textareaRef.current && document.activeElement === textareaRef.current) {
        textareaRef.current.blur();
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (recentSearchesRef.current && !recentSearchesRef.current.contains(event.target) &&
          textareaRef.current && !textareaRef.current.contains(event.target)) {
        setShowRecentSearches(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const togglePause = () => {
    setIsPaused(!isPaused);
  };

  const clearInput = () => {
    setInputQuery('');
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  };

  const truncateSelectedText = (text) => {
    return text.length > 20 ? text.substring(0, 17) + '...' : text;
  };

  const handleSelectRecentQuery = (recentQuery) => {
    setInputQuery(recentQuery.userQuery);
    setShowRecentSearches(false);
    setHighlightedIndex(-1);
    onSubmit(recentQuery);
  };

  const handleFocus = () => {
    setIsFocused(true);
    if (!isFollowUpMode) {
      setShowRecentSearches(true);
      loadRecentSearchesFromLocalStorage();
      refreshRecentSearches();
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mb-4 relative">
      <div className="relative">
        <div className="relative">
          {isFollowUpMode && selectedText && (
            <div 
              ref={chipRef}
              className={`selected-text-chip absolute left-2.5 top-3 text-xs px-2 py-1 rounded-full ${
                isCardTitle ? 'bg-white text-gray-700' : 'bg-secondary text-text'
              }`}
            >
              {truncateSelectedText(selectedText)}
            </div>
          )}
          <textarea
            ref={textareaRef}
            value={inputQuery}
            onChange={handleTextareaChange}
            onKeyDown={handleKeyDown}
            onFocus={handleFocus}
            onBlur={() => {
              setIsFocused(false);
              setTimeout(() => setShowRecentSearches(false), 200);
            }}
            placeholder={placeholder}
            className={`w-full py-3 bg-gray-700 rounded-full focus:outline-none focus:ring-2 focus:ring-secondary text-white text-base placeholder-gray-400 min-h-[48px] max-h-[72px] resize-none overflow-y-auto ${
              isFollowUpMode ? 'pl-4' : 'pl-10'
            } pr-36 ${
              isFocused ? 'ring-2 ring-secondary' : ''
            }`}
            disabled={isLoading}
            rows={1}
            style={{
              lineHeight: '24px',
              transition: 'height 0.1s ease-out',
              paddingLeft: isFollowUpMode && selectedText ? `${10 + (chipRef.current?.offsetWidth || 0) + 5}px` : undefined,
            }}
          />
        </div>
        {!isFollowUpMode && (
          <img
            src="/favicon.ico"
            alt="Company Icon"
            className="absolute left-2.5 top-3 w-6 h-6"
          />
        )}
        <button
          type="submit"
          className={`absolute right-2 top-3 p-1 rounded-full transition-colors duration-200 ${
            inputQuery.trim() ? 'text-secondary hover:bg-secondary hover:bg-opacity-10' : 'text-text-lighter'
          }`}
          disabled={isLoading || !inputQuery.trim()}
        >
          <SendHorizontal size={20} />
        </button>
        <button
          type="button"
          onClick={inputQuery ? clearInput : togglePause}
          className={`absolute right-12 top-3 p-1 rounded-full transition-colors duration-200 ${
            inputQuery ? 'text-secondary hover:bg-secondary hover:bg-opacity-10' : 'text-text-lighter'
          }`}
        >
          {inputQuery ? <X size={20} /> : isPaused ? <Play size={20} /> : <Pause size={20} />}
        </button>
      </div>
      {!isFollowUpMode && (
        <div ref={recentSearchesRef}>
          <RecentSearches
            onSelectQuery={handleSelectRecentQuery}
            isVisible={showRecentSearches}
            setIsVisible={setShowRecentSearches}
            recentSearches={filteredRecentSearches}
            highlightedIndex={highlightedIndex}
            onKeyDown={handleKeyDown}
          />
        </div>
      )}
      {isLoading && (
        <div className="flex justify-center items-center mt-4">
          <div className="bubbling-loader">
            <div></div>
            <div></div>
            <div></div>
          </div>
          <span className="ml-2 text-[#4B5563] text-base">Processing query...</span>
        </div>
      )}
    </form>
  );
};

export default QueryInput;