import React, { useState, useEffect, useRef } from 'react';

const RecentSearches = ({ onSelectQuery, isVisible, setIsVisible, recentSearches, highlightedIndex, onKeyDown }) => {
  const listRef = useRef(null);

  useEffect(() => {
    if (highlightedIndex !== -1 && listRef.current) {
      const highlightedElement = listRef.current.children[highlightedIndex];
      if (highlightedElement) {
        highlightedElement.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
      }
    }
  }, [highlightedIndex]);

  if (!isVisible || recentSearches.length === 0) return null;

  return (
    <div className="absolute z-10 w-full mt-1 bg-gray-700 border border-secondary rounded-md shadow-lg focus-within:ring-2 focus-within:ring-secondary">
      <ul ref={listRef} className="py-1 max-h-60 overflow-y-auto">
        {recentSearches.map((search, index) => (
          <li
            key={search.id}
            className={`px-4 py-2 hover:bg-gray-800 cursor-pointer text-white transition-all duration-200 ease-in-out rounded-md border border-transparent focus:ring-2 focus:ring-secondary ${
              index === highlightedIndex ? 'bg-gray-800' : ''
            }`}
            onClick={() => onSelectQuery(search)}
          >
            {search.userQuery}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RecentSearches;