import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import logo from '../assets/logo.png';
import { LayoutDashboard, FileStack, PieChart, LocateFixed, LogIn, UserPlus } from 'lucide-react';
import config from './config';

const Sidebar = ({ open, setOpen }) => {
  const { currentUser, logout, isAdmin } = useAuth();
  const location = useLocation();

  console.log('Sidebar - currentUser:', !!currentUser, 'isAdmin:', isAdmin);

  const handleLogoClick = () => {
    window.location.href = '/';
  };

  const pageConfig = {
    Dashboard: { path: '/', icon: LayoutDashboard },
    Reports: { path: '/static-dashboard', icon: FileStack },
    Playground: { path: '/dynamic', icon: PieChart },
    Optimization: { path: '/optimization', icon: LocateFixed }
  };

  const MenuItem = ({ label }) => {
    const { path, icon: Icon } = pageConfig[label];
    const isActive = location.pathname === path;
    return (
      <Link
        to={path}
        className={`flex items-center px-4 py-2 my-1 rounded-lg text-sm ${
          isActive
            ? 'bg-gray-100 font-medium text-gray-900'
            : 'text-gray-700 hover:bg-gray-50'
        }`}
        onClick={() => {
          if (window.innerWidth < 1024) {
            setOpen(false);
          }
        }}
      >
        <Icon className="mr-3 h-4 w-4" />
        <span>{label}</span>
      </Link>
    );
  };

  const renderMenuItems = () => {
    const pages = isAdmin ? config.sidebarPages.admin : config.sidebarPages.user;
    return pages.map((label, index) => (
      <MenuItem key={index} label={label} />
    ));
  };

  return (
    <>
      <div
        className={`fixed inset-0 z-20 transition-opacity bg-black opacity-50 lg:hidden ${
          open ? 'block' : 'hidden'
        }`}
        onClick={() => setOpen(false)}
      ></div>

      <div
        className={`fixed inset-y-0 left-0 z-30 w-[12.8rem] overflow-y-auto transition duration-300 transform bg-white lg:translate-x-0 lg:static lg:inset-0 ${
          open ? 'translate-x-0 ease-out' : '-translate-x-full ease-in'
        }`}
      >
        <div 
          className="flex items-center justify-start pl-4 mt-5 mb-4 cursor-pointer"
          onClick={handleLogoClick}
        >
          <img src={logo} alt="Logo" className="h-8 w-auto" />
        </div>

        <nav className="px-2">
          {currentUser && renderMenuItems()}
        </nav>

        <div className="mt-auto px-2 mb-4 absolute bottom-0 w-full">
          {currentUser ? (
            <button
              onClick={() => {
                logout();
                if (window.innerWidth < 1024) {
                  setOpen(false);
                }
              }}
              className="w-full flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 rounded-lg"
            >
              <LogIn className="mr-3 h-4 w-4" />
              <span>Sign Out</span>
            </button>
          ) : (
            <>
              <Link to="/login" className="w-full flex items-center px-4 py-2 my-1 text-sm text-gray-700 hover:bg-gray-50 rounded-lg">
                <LogIn className="mr-3 h-4 w-4" />
                <span>Sign In</span>
              </Link>
              <Link to="/signup" className="w-full flex items-center px-4 py-2 my-1 text-sm text-gray-700 hover:bg-gray-50 rounded-lg">
                <UserPlus className="mr-3 h-4 w-4" />
                <span>Sign Up</span>
              </Link>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Sidebar;