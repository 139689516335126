import React, { useState, useEffect } from 'react';
import { formatColumnName } from './dataProcessing';
import SortingControls from './SortingControls';

const ControlPanel = ({ 
  selectedXAxis, setSelectedXAxis, 
  selectedYAxis, setSelectedYAxis,
  sortColumn, setSortColumn, 
  sortOrder, setSortOrder, 
  columns,
  schema,
  isVisible
}) => {
  const [height, setHeight] = useState(0);
  const contentRef = React.useRef(null);

  useEffect(() => {
    if (isVisible) {
      const height = contentRef.current.scrollHeight;
      setHeight(height);
    } else {
      setHeight(0);
    }
  }, [isVisible]);

  if (columns.length < 2) {
    return null;
  }

  const handleYAxisChange = (e) => {
    const column = e.target.value;
    setSelectedYAxis(column);
  };

  return (
    <div 
      className="overflow-hidden transition-all duration-300 ease-in-out"
      style={{ maxHeight: `${height}px` }}
    >
      <div ref={contentRef} className="w-full mt-2 bg-gray-50 p-2 rounded-md">
        <div className="flex flex-wrap gap-4 justify-end">
          <div className="flex flex-col space-y-2 min-w-[120px]">
            <label className="block text-sm font-medium text-gray-700">X-Axis</label>
            <select
              value={selectedXAxis}
              onChange={(e) => setSelectedXAxis(e.target.value)}
              className="w-full text-sm py-1 px-2 border rounded-md bg-white"
            >
              {columns.map(column => (
                <option key={column} value={column}>{formatColumnName(column)}</option>
              ))}
            </select>
          </div>
          <div className="flex flex-col space-y-2 min-w-[120px]">
            <label className="block text-sm font-medium text-gray-700">Y-Axis</label>
            <select
              value={selectedYAxis}
              onChange={handleYAxisChange}
              className="w-full text-sm py-1 px-2 border rounded-md bg-white"
            >
              {columns.map(column => (
                <option key={column} value={column}>{formatColumnName(column)}</option>
              ))}
            </select>
          </div>
          <div className="flex justify-end">
            <SortingControls
              sortColumn={sortColumn}
              setSortColumn={setSortColumn}
              sortOrder={sortOrder}
              setSortOrder={setSortOrder}
              columns={columns}
              schema={schema}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ControlPanel;